import { Button, Heading } from '@/Components';
import { WalletI } from '@/interfaces/Wallet.interfaces';
import { useProviders } from '@/providers';
import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import Identicon from '@polkadot/react-identicon';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { ModalCommandsI } from '../interface';

export default function SelectAccount({
    event,
    state,
    actions,
}: ModalCommandsI) {
    const [enableDone, setEnableDone] = useState(false);

    const [tempWalletSelected, setTempWalletSelected] =
        useState<InjectedAccountWithMeta>();
    const [wallets, setWallets] = useState<InjectedAccountWithMeta[]>([]);
    const { connectWallet } = useProviders();

    const currentWallet = connectWallet.getCurrentAccount();
    const provider = connectWallet.getCurrentProvider();

    useEffect(() => {
        setEnableDone(!!tempWalletSelected);
    }, [tempWalletSelected]);

    const onSelectWallet = (wallet: InjectedAccountWithMeta) => {
        if (wallet) setTempWalletSelected(wallet);
    };

    useEffect(() => {
        onRefreshWallets().then(() => {
            if (currentWallet) setTempWalletSelected(currentWallet);
        });
    }, [provider]);

    const onRefreshWallets = () =>
        connectWallet.getAvailableWallets().then((wallets) => {
            if (wallets) setWallets(wallets);
        });

    const walletName = (wallet: string) => {
        switch (wallet) {
            case 'polkadot-js':
                return 'Polkadot.js';
            case 'novawallet':
                return 'Nova Wallet';
            case 'subwallet-js':
                return 'SubWallet';
            case 'mimir':
                return 'Mimir';
            case 'talisman':
                return 'Talisman';
            case 'signet':
                return 'Signet';
            default:
                return 'Polkadot.js';
        }
    };

    const Option = (propsOpt: {
        wallet: WalletI;
        isSelected?: boolean;
        onClick: () => void;
    }) => (
        <button
            className={`flex w-full gap-3 rounded-lg border border-transparent p-2 transition hover:bg-[#dbc4d0] ${propsOpt.isSelected && '!border-[#E6007A] bg-[#dbc4d0]'}`}
            onClick={propsOpt.onClick}
        >
            <Identicon
                theme="polkadot"
                value={propsOpt.wallet.address}
                size={32}
            />

            <div className="flex w-[calc(100%-12px-32px)] flex-grow flex-col items-start gap-1 object-contain">
                <Heading
                    size="headingxs"
                    as="b"
                    className="font-extrabold leading-[15px] !text-black-900"
                    children={propsOpt.wallet.meta.name}
                />
                <Heading
                    size="texts"
                    as="div"
                    className="w-full overflow-hidden text-ellipsis font-normal leading-[15px] !text-black-900"
                    children={propsOpt.wallet.address}
                />
            </div>
        </button>
    );

    const onDone = () => {
        actions.closeCurrentModalModal();
        if (tempWalletSelected)
            connectWallet.setWalletAccount(tempWalletSelected);

        event('done');
    };

    const Wallets = () =>
        wallets?.map((opt, i) => (
            <Option
                wallet={opt}
                key={i}
                isSelected={tempWalletSelected?.address == opt.address}
                onClick={() => onSelectWallet(opt)}
            />
        ));

    const header = (
        <>
            <div>
                <Heading
                    as="h1"
                    className="bg-gradient bg-clip-text !text-transparent md:text-[28px]"
                >
                    Choose your account
                </Heading>
                <Heading
                    size="texts"
                    as="h2"
                    className="leading-[15px] !text-black-900 mt-2"
                >
                    Click on the account you want to sign in with.
                </Heading>
            </div>

            <div className="mt-4 h-px self-stretch bg-gray-400" />
        </>
    );

    const footer = (
        <>
            <div className="h-px self-stretch bg-gray-400" />

            <div className="mt-4 flex w-full justify-between gap-x-[20px] gap-y-2 sm:flex-col-reverse">
                <Button
                    size="xs"
                    shape="round"
                    color="pink_500_pink_A100"
                    className="min-w-[160px] flex-grow bg-gradient bg-clip-text text-transparent sm:w-full sm:px-4"
                    onClick={actions.closeCurrentModalModal}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    size="xs"
                    variant="gradient"
                    shape="round"
                    color="pink_500_pink_A100"
                    className="min-w-[160px] flex-grow disabled:cursor-default disabled:opacity-25 sm:w-full sm:px-4"
                    onClick={onDone}
                    disabled={!enableDone}
                >
                    Done
                </Button>
            </div>
        </>
    );

    return (
        <Dialog
            resizable={false}
            header={header}
            footer={footer}
            visible={state}
            onHide={actions.closeCurrentModalModal}
            maskClassName="bg-[rgba(0,0,0,0.75)]"
            className="custom-scrollbar max-w-[min(25rem,calc(100vw-32px))] gap-4 rounded-md bg-blue_gray-100 px-[30px] py-[26px] outline-none sm:bottom-0 sm:mt-auto sm:max-h-[100%] sm:w-full sm:max-w-full sm:rounded-none sm:p-4"
        >
            <div className="custom-scrollbar w-full overflow-y-auto pr-2">
                {wallets && wallets?.length > 0 ? (
                    <Wallets />
                ) : (
                    <div className="flex flex-col gap-2 text-center">
                        <span>
                            { connectWallet.getWalletExtension() === 'polkadot-js' ? (
                                'No wallet addresses were found. Please check if your Polkadot.js extension is connected by clicking on "Connect Accounts" button located at the extension\'s modal.'
                                ) : (
                                `No wallet addresses were found. Please check if you have given permission from ${walletName(connectWallet.getWalletExtension())} to connect to PolkaIdentity using your wallet.`
                                )
                            }
                        </span>
                        <button
                            className="font-bold text-[#E6007A] mt-1 mb-1"
                            onClick={onRefreshWallets}
                        >
                            Click here to refresh
                        </button>
                    </div>
                )}
            </div>
        </Dialog>
    );
}
