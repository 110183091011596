import SelectAccount from './components/SelectAccount';
import Teleport from './components/Teleport';
import SelectWallet from '@/modals/components/SelectWallet';

const modals = {
    teleport: Teleport,
    'select-account': SelectAccount,
    'select-wallet': SelectWallet,
};

export default modals;
