import { Button, Heading, Input } from '@/Components';
import { useProviders } from '@/providers';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { GoAlertFill } from 'react-icons/go';
import { ModalCommandsI } from '../interface';
import { toast } from 'react-toastify';
import BigNumber from 'bignumber.js';

interface TeleportInput {
    beneficiary: string;
    amount: string;
}

export default function Teleport({ event, state, actions }: ModalCommandsI) {
    const [inputValue, setInputValue] = useState<TeleportInput>({
        beneficiary: '',
        amount: '',
    });
    type inputNamesType = keyof typeof inputValue;

    const [enableDone, setEnableDone] = useState(false);
    const [loadingDone, setLoadingDone] = useState(false);
    const { connectWallet } = useProviders();
    const currentWallet = connectWallet.getCurrentAccount();
    const relayBalance = connectWallet.getHumanRelayBalance();

    useEffect(() => {
        connectWallet.initRelayApi();
    }, []);

    const InputElement = (inputProps: {
        name: inputNamesType;
        label: string;
        placeholder: string;
        type: React.HTMLInputTypeAttribute;
        icon?: JSX.Element;
    }) => {
        const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const key = event.target.name as inputNamesType;
            setInputValue((values) => ({
                ...values,
                [key]: event.target.value,
            }));
        };

        // @ts-ignore
        // const errorArray: string[] = (props.errors?.errors && inputProps.name in props.errors?.errors && props.errors?.errors[inputProps.name]) ?? []
        // const errorMessage = errorArray?.join(" ")
        // const isError = errorArray.length > 0

        if (inputProps.name === "beneficiary" && inputValue.beneficiary === "") {
            inputValue.beneficiary = currentWallet?.address;
        }

        const value = inputValue[inputProps.name as inputNamesType];

        return (
            <div className="flex w-full flex-col items-start gap-1.5 self-stretch">
                <Heading size="headingxs" as="h2" className={'!text-gray-600'}>
                    {inputProps.label}
                </Heading>
                <div className="relative w-full">
                    <Input
                        {...{ onChange, name: inputProps.name, value }}
                        shape="round"
                        type={inputProps.type}
                        placeholder={inputProps.placeholder}
                        className={`w-full !text-gray-700 sm:pr-4`}
                    />
                    {inputProps.icon && (
                        <div className="absolute bottom-0 right-4 top-0 m-auto h-fit">
                            {inputProps.icon}
                        </div>
                    )}
                    {/* {isError &&
                    <span className="flex h-0 sm:h-2 font-bold text-[10px] text-red-600 leading-5">
                        {errorMessage}
                    </span>} */}
                </div>
            </div>
        );
    };

    const onDone = () => {
        requestTeleport();
    };

    useEffect(() => {
        setEnableDone(
            !isNaN(Number(inputValue.amount)) &&
                inputValue.beneficiary.length > 0,
        );
    }, [inputValue]);

    const transactionFailedToast = (message: string | undefined | null) => {
        if (message?.includes("genesisHash error")) {
            const network = connectWallet.getNetwork() === "polkadot" ? "People Polkadot" : "People-Kusama";
            message = `Failed to request signature, check if you are connected to ${network} and not to the relay chain.`;
        }

        toast.error(message ?? "Teleport transaction has failed. Please try again.", {
            closeOnClick: true,
            theme: "dark"
        });
    };

    const requestTeleport = () => {
        setLoadingDone(true);
        if (isNaN(Number(inputValue.amount))) return;

        let amount = BigNumber(inputValue.amount).shiftedBy(providerCurrency === 'KSM' ? 12 : 10);
        connectWallet.runTeleport(currentWallet, amount.toString()).then(() => {
            setLoadingDone(false);
            event('close');
        }).catch((err) => {
            setLoadingDone(false);
            transactionFailedToast(err.message);
        });
    };

    const onChangeWallet = () => {
        actions.openModal('select-account', {
            done: () => actions.openModal('teleport'),
        });
    };

    const header = (
        <div className="flex w-full flex-col">
            <div>
                <Heading
                    as="h1"
                    className="bg-gradient bg-clip-text !text-transparent md:text-[28px]"
                >
                    Teleport
                </Heading>
            </div>
            <div className="mt-4 h-px self-stretch bg-gray-400" />
        </div>
    );

    const footer = (
        <>
            <div className="mx-[50px] flex w-full flex-wrap-reverse justify-center gap-2 lg:mx-0 md:mx-0">
                <Button
                    size="xs"
                    shape="round"
                    color="pink_500_pink_A100"
                    className="max-w-[160px] grow basis-[160px] bg-gradient bg-clip-text text-transparent lg:text-[17px] sm:max-w-full sm:basis-[100%] sm:px-4"
                    onClick={actions.closeCurrentModalModal}
                >
                    Cancel
                </Button>
                <Button
                    type="submit"
                    size="xs"
                    variant="gradient"
                    shape="round"
                    color="pink_500_pink_A100"
                    //     className="absolute right-1 !h-8 min-w-[140px] disabled:opacity-25 sm:px-4"
                    className="flex max-w-[160px] grow basis-[160px] gap-3 disabled:opacity-25 lg:text-[17px] sm:w-full sm:min-w-0 sm:max-w-full sm:basis-[100%] sm:px-4"
                    onClick={onDone}
                    disabled={!enableDone || loadingDone}
                >
                    Done
                    {loadingDone && (
                        <span className="min-h-4 min-w-4 animate-spin rounded-full border-t border-[#fff]" />
                    )}
                </Button>
            </div>
        </>
    );

    const minDeposit = connectWallet.getMinDeposit();
    const providerCurrency = connectWallet.getCurrentProviderCurrency();

    return (
        <Dialog
            resizable={false}
            header={header}
            footer={footer}
            visible={state}
            onHide={actions.closeCurrentModalModal}
            maskClassName="bg-[rgba(0,0,0,0.75)]"
            className="custom-scrollbar max-w-[min(32rem,calc(100vw-32px))] gap-4 rounded-md bg-blue_gray-100 px-[30px] py-[26px] outline-none sm:bottom-0 sm:mt-auto sm:max-h-[100%] sm:w-full sm:max-w-full sm:rounded-none sm:p-4"
        >
            <div className="custom-scrollbar flex w-full flex-col gap-4 overflow-y-auto pr-2">
                <div className="flex w-full flex-col items-start gap-1.5 self-stretch">
                    <Heading
                        size="headingxs"
                        as="h2"
                        className={'flex w-full justify-between !text-gray-600'}
                    >
                        <span>Your Address</span>
                        <span className="text-black-900">
                            Balance at {providerCurrency === 'KSM' ? 'Kusama' : 'Polkadot'}:{' '}
                            <Heading
                                as="span"
                                className="bg-gradient bg-clip-text !text-sm !font-extrabold !text-transparent"
                            >
                                {relayBalance} {providerCurrency}
                            </Heading>
                        </span>
                    </Heading>
                    <div className="relative flex h-[40px] w-full items-center justify-between rounded-md border border-solid border-gray-600 pl-4 pr-[34px] text-sm !text-gray-500">
                        <span className="max-w-[calc(100%-140px)] overflow-hidden text-ellipsis">
                            {currentWallet.address}
                        </span>
                        <Button
                            type="button"
                            size="xs"
                            variant="gradient"
                            shape="round"
                            color="pink_500_pink_A100"
                            className="absolute right-1 !h-8 min-w-[140px] disabled:opacity-25 sm:px-4"
                            onClick={onChangeWallet}
                        >
                            Change Wallet
                        </Button>
                    </div>
                </div>

                {InputElement({
                    name: 'beneficiary',
                    label: 'Beneficiary Address',
                    placeholder: 'Enter beneficiary',
                    type: 'text',
                })}
                {InputElement({
                    name: 'amount',
                    label: 'Amount',
                    placeholder: `Enter ${providerCurrency} amount`,
                    type: 'number',
                    icon: (
                        <>
                            <span className="flex gap-2 font-bold text-neutral-500">
                                {/* {providerCurrency == "KSM" && <img src="/icons/ksm.png" alt="ksm icon" />} */}
                                {/* {providerCurrency == "DOT" && <Assets.svg.polkaid_logo/>} */}
                                {providerCurrency}
                            </span>
                        </>
                    ),
                })}
            </div>

            <span className="mt-4 flex gap-2 text-xs">
                <GoAlertFill fill="#E70071 " />
                <span>
                    You need to teleport{' '}
                    <b>
                        {minDeposit} {providerCurrency}
                    </b>{' '}
                    more for the previous transaction.
                </span>
            </span>
        </Dialog>
    );
}
