import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { ModalEventsI, ModalK, ModalProviderI, ModalState } from './interface';
import modals from './modals';

const ModalsContext = createContext<ModalProviderI>(null as any);

export function ModalProvider({ children }: PropsWithChildren) {
    const [modalState, setModalState] = useState<ModalState>(
        (() => {
            let initialValue = {};
            Object.keys(modals).forEach(
                (m) => (initialValue = { ...initialValue, [m]: undefined }),
            );
            return initialValue as ModalState;
        })(),
    );

    const changeModalState = (modal: ModalK, state: ModalEventsI | undefined) =>
        setModalState((old) => ({ ...old, [modal]: state }));
    const functions: ModalProviderI = {
        openModal(name, props) {
            changeModalState(name, props ?? {});
        },
        closeModal(name) {
            changeModalState(name, undefined);
        },
        getCurrentOpennedModal() {
            if (!modalState) return;
            let currentModalOpen: ModalK | undefined;

            Object.keys(modalState).forEach((modal) => {
                if (modalState[modal as ModalK])
                    currentModalOpen = modal as ModalK;
            });

            return currentModalOpen;
        },
        closeCurrentModalModal() {
            const currentOpennedModal = functions.getCurrentOpennedModal();
            if (!currentOpennedModal) return;

            changeModalState(currentOpennedModal, undefined);
        },
    };

    const CurrentModal = () => {
        const currentModalOpen = functions.getCurrentOpennedModal();
        if (!currentModalOpen || !modalState) return <></>;

        const Modal = modals[currentModalOpen];
        const state = modalState[currentModalOpen];

        const isOpen = !(state == undefined);
        return (
            <Modal
                actions={functions}
                event={(event, props) => {
                    if (state && state[event]) state[event](props);
                }}
                state={isOpen}
            />
        );
    };

    return (
        <ModalsContext.Provider value={functions}>
            {children}
            <CurrentModal />
        </ModalsContext.Provider>
    );
}

export const useModal = () => useContext(ModalsContext);
