import { Heading } from '@/Components';
import { Dialog } from 'primereact/dialog';
import { ModalCommandsI } from '../interface';
import Assets from '@/Assets';
import If from '@/Components/If';
import { isMimirReady, MIMIR_REGEXP } from '@mimirdev/apps-inject';
import { useEffect, useState } from 'react';
import { InjectedWindow } from '@polkadot/extension-inject/types';
import { useProviders } from '@/providers';
// import { SignetSdk, useSignetSdk } from '@talismn/signet-apps-sdk';

//
// import UniversalProvider from '@walletconnect/universal-provider'
// import { WalletConnectModal } from '@walletconnect/modal'
//
// const provider = await UniversalProvider.init({
//     projectId: '3bd99a5bc9297e41c682e3a5cbd1397f',
//     relayUrl: 'wss://relay.walletconnect.com'
// })
//
// const params = {
//     requiredNamespaces: {
//         polkadot: {
//             methods: ['polkadot_signTransaction'],
//             chains: [
//                 'polkadot:91b171bb158e2d3848fa23a9f1c25182', // polkadot
//                 'polkadot:b0a8d493285c2df73290dfb7e61f870f', // polkadot
//             ],
//             events: []
//         }
//     }
// }
//
// const { uri, approval } = await provider.client.connect(params)
//
// const walletConnectModal = new WalletConnectModal({
//     projectId: '3bd99a5bc9297e41c682e3a5cbd1397f'
// })
//




export default function SelectWallet({
                                         event,
                                         state,
                                         actions
                                     }: ModalCommandsI) {

    const { connectWallet } = useProviders();
    const [installedWallets, setInstalledWallets] = useState<any>({});

    const getWallet = async () => {
        const openInIframe = window !== window.parent;
        if (openInIframe) {

            isMimirReady().then((origin) => {
                if (!origin) return;
                if (MIMIR_REGEXP.test(origin)) {
                    setInstalledWallets({
                        'polkadot-js': false,
                        'subwallet-js': false,
                        'novawallet': false,
                        'talisman': false,
                        'mimir': true,
                        'signet': false,
                    })
                }
            });

            return;
        }

        const injectedWindow = window as Window & InjectedWindow;
        const injectedWallets = injectedWindow.injectedWeb3 || {}

        let polkadotjs = false;
        let subwallet = false;
        let talisman = false;
        let nova: boolean = (window as any).walletExtension?.isNovaWallet ?? false;

        const polkadotjsVersion = injectedWallets['polkadot-js']?.version;
        const subwalletVersion = injectedWallets['subwallet-js']?.version;
        const talismanVersion = injectedWallets['talisman']?.version;

        if (polkadotjsVersion) {
            polkadotjs = true;
        }

        if (subwalletVersion) {
            subwallet = true;
            nova = false;

            if (polkadotjsVersion) {
                polkadotjs = polkadotjsVersion != subwalletVersion;
            }
        }

        if (talismanVersion) {
            talisman = true;
        }

        if (nova) {
            polkadotjs = false;
        }

        setInstalledWallets({
            'polkadot-js': polkadotjs,
            'subwallet-js': subwallet,
            'novawallet': nova,
            'talisman': talisman,
            'mimir': false,
            'signet': false,
        })
    };

    useEffect(() => {
        getWallet();
    }, []);

    const walletUrl = (wallet: string) => {
        switch (wallet) {
            case 'polkadot-js':
                return 'https://polkadot.js.org/extension/';
            case 'novawallet':
                return 'https://novawallet.io/';
            case 'subwallet-js':
                return 'https://subwallet.app/';
            case 'mimir':
                return 'https://app.mimir.global';
            case 'talisman':
                return 'https://talisman.xyz/';
            case 'signet':
                return 'https://polkadotmultisig.com';
            default:
                return 'https://polkadot.js.org/extension/';
        }
    };

    const walletIcon = (wallet: string) => {
        switch (wallet) {
            case 'polkadot-js':
                return <Assets.svg.polkadotjs />;
            case 'novawallet':
                return <Assets.svg.novawallet />;
            case 'subwallet-js':
                return <Assets.svg.subwallet />;
            case 'mimir':
                return <Assets.svg.mimir />;
            case 'talisman':
                return <Assets.svg.talisman />;
            case 'signet':
                return <Assets.svg.signet />;
            default:
                return <Assets.svg.polkadotjs />;
        }
    };

    const walletName = (wallet: string) => {
        switch (wallet) {
            case 'polkadot-js':
                return 'Polkadot.js';
            case 'novawallet':
                return 'Nova Wallet';
            case 'subwallet-js':
                return 'SubWallet';
            case 'mimir':
                return 'Mimir';
            case 'talisman':
                return 'Talisman';
            case 'signet':
                return 'Signet';
            default:
                return 'Polkadot.js';
        }
    };

    const Option = (propsOpt: {
        wallet: string;
        isSelected?: boolean;
        isInstalled?: boolean;
        onClick?: () => void;
    }) => (
        <button
            className={`flex w-full gap-3 rounded-lg border border-[#C5C5C5] p-2 transition ${propsOpt.isInstalled ? 'hover:bg-[#dbc4d0]' : 'bg-[#cacaca]'} mb-2 items-center ${propsOpt.isSelected && '!border-[#E6007A] bg-[#dbc4d0]'}`}
            onClick={propsOpt.onClick}
            disabled={!propsOpt.isInstalled}
        >
            <div className="inline-flex mr-1">
                {walletIcon(propsOpt.wallet)}
            </div>

            <div className="justify-between inline-flex items-center w-full">
                <Heading
                    size="headingxs"
                    as="b"
                    className="font-extrabold !text-black-900"
                    children={walletName(propsOpt.wallet)}
                />

                <If conditional={!propsOpt.isInstalled}>
                    <a href={walletUrl(propsOpt.wallet)} target="_blank">
                        <div className="inline-flex gap-2 items-center">
                            <span className="text-black-900 text-xs">Not installed</span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="inline-flex w-5 h-5 text-theme500">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                      d="M20.4855 4.22177C18.4352 2.17152 15.1111 2.17152 13.0609 4.22177L11.6467 5.63598C11.3538 5.92888 11.3538 6.40375 11.6467 6.69664C11.9395 6.98954 12.4144 6.98954 12.7073 6.69664L14.1215 5.28243C15.586 3.81797 17.9604 3.81797 19.4248 5.28243C20.8893 6.7469 20.8893 9.12127 19.4248 10.5857L16.2428 13.7677C14.9736 15.0369 12.9159 15.0369 11.6467 13.7677L10.586 12.7071C10.2931 12.4142 9.81823 12.4142 9.52533 12.7071C9.23244 12.9999 9.23244 13.4748 9.52533 13.7677L10.586 14.8284C12.441 16.6834 15.4485 16.6834 17.3035 14.8284L20.4855 11.6464C22.5357 9.59614 22.5357 6.27202 20.4855 4.22177ZM7.75738 10.2322C9.02658 8.96297 11.0844 8.96297 12.3536 10.2322L13.4142 11.2928C13.7071 11.5857 14.182 11.5857 14.4749 11.2928C14.7678 10.9999 14.7678 10.5251 14.4749 10.2322L13.4142 9.17152C11.5592 7.31653 8.55171 7.31653 6.69672 9.17152L3.51474 12.3535C1.46449 14.4037 1.46449 17.7279 3.51474 19.7781C5.56499 21.8284 8.88911 21.8284 10.9394 19.7781L12.3536 18.3639C12.6465 18.071 12.6465 17.5961 12.3536 17.3032C12.0607 17.0104 11.5858 17.0104 11.2929 17.3032L9.8787 18.7175C8.41423 20.1819 6.03987 20.1819 4.5754 18.7175C3.11093 17.253 3.11093 14.8786 4.5754 13.4142L7.75738 10.2322Z"
                                      fill="currentColor"></path>
                            </svg>
                        </div>
                    </a>
                </If>
            </div>
        </button>
    );

    const onDone = (extension: string) => {
        connectWallet.setWalletExtension(extension);
        actions.closeCurrentModalModal();
        event('done');
    };

    const header = (
        <>
            <div>
                <Heading
                    as="h1"
                    className="bg-gradient bg-clip-text !text-transparent md:text-[28px]"
                >
                    Connect your wallet
                </Heading>
                <Heading
                    size="texts"
                    as="h2"
                    className="leading-[15px] !text-black-900 mt-2"
                >
                    Select a wallet application to connect
                </Heading>
            </div>

            <div className="mt-4 h-px self-stretch bg-gray-400" />
        </>
    );

    return (
        <Dialog
            resizable={false}
            header={header}
            visible={state}
            onHide={actions.closeCurrentModalModal}
            maskClassName="bg-[rgba(0,0,0,0.75)]"
            className="custom-scrollbar max-w-[min(32rem,calc(100vw-32px))] gap-4 rounded-md bg-blue_gray-100 px-[30px] py-[26px] outline-none sm:bottom-0 sm:mt-auto sm:max-h-[100%] sm:w-full sm:max-w-full sm:rounded-none sm:p-4"
        >
            <div className="custom-scrollbar w-full overflow-y-auto pr-2">
                <Option
                    wallet="polkadot-js"
                    key="polkadot-js"
                    isSelected={false}
                    isInstalled={installedWallets['polkadot-js']}
                    onClick={() => onDone('polkadot-js')}
                />
                <Option
                    wallet="mimir"
                    key="mimir"
                    isSelected={false}
                    isInstalled={installedWallets['mimir']}
                    onClick={() => onDone('mimir')}
                />
                <Option
                    wallet="subwallet-js"
                    key="subwallet-js"
                    isSelected={false}
                    isInstalled={installedWallets['subwallet-js']}
                    onClick={() => onDone('subwallet-js')}
                />
                <Option
                    wallet="talisman"
                    key="talisman"
                    isSelected={false}
                    isInstalled={installedWallets['talisman']}
                    onClick={() => onDone('talisman')}
                />
                <Option
                    wallet="novawallet"
                    key="novawallet"
                    isSelected={false}
                    isInstalled={installedWallets['novawallet']}
                    onClick={() => onDone('polkadot-js')}
                />
                {/*<Option*/}
                {/*    wallet="walletconnect"*/}
                {/*    key="walletconnect"*/}
                {/*    isSelected={false}*/}
                {/*    isInstalled={true}*/}
                {/*    onClick={() => startWalletConnect()}*/}
                {/*/>*/}
                {/*<Option*/}
                {/*    wallet="signet"*/}
                {/*    key="signet"*/}
                {/*    isSelected={false}*/}
                {/*    isInstalled={installedWallets['signet']}*/}
                {/*    onClick={() => onDone('signet')}*/}
                {/*/>*/}
            </div>
        </Dialog>
    );
}
